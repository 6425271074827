import Input from 'components/input'
import { forwardRef } from 'react'
import LegacyTextInput from './text-input.legacy'

const TextInput = forwardRef(({ legacyStyle, ...props }, ref) =>
  legacyStyle ? (
    <LegacyTextInput ref={ref} {...props} />
  ) : (
    <Input ref={ref} type="text" {...props} />
  )
)

export default TextInput
