import * as R from 'ramda'
import { isObject } from '../util/conditions'
import { thru } from '../util/functions'
import emailDomains from './email-domains.json'

const ejectSuffixValue = R.when(isObject, R.prop('value'))
const expandSuffixesForBasename = R.uncurryN(2, basename =>
  R.map(suffix => `${basename}.${ejectSuffixValue(suffix)}`)
)

export const validEmailDomains = thru(
  emailDomains,
  Object.entries,
  R.chain(([basename, { suffixes }]) => expandSuffixesForBasename(basename, suffixes))
)

export const validBasenames = Object.keys(emailDomains)

export const validSuffixesForBasename = R.pipe(
  R.prop(R.__, emailDomains),
  R.prop('suffixes'),
  R.defaultTo([]),
  R.map(ejectSuffixValue)
)

export const basenameWeight = basename => emailDomains[basename]?.weight ?? 1

export const suffixWeight = R.curry(
  R.memoizeWith(R.unapply(R.join('_')), (basename, suffix) =>
    thru(
      emailDomains,
      R.pathOr({}, [basename, 'suffixes']),
      R.filter(isObject),
      R.find(R.whereEq({ value: suffix })),
      R.propOr(1, 'weight')
    )
  )
)
