import * as R from 'ramda'

const addMissingBasenameSuffixSeparator = R.replace(
  /((\.)?com(\.au)?)$/,
  (fullMatch, _, separator) => (separator ? fullMatch : '.' + fullMatch)
)

const addMissingCoCcSld = R.replace(/(\.[a-z]{2,})?\.(nz)$/, (fullMatch, missingCo, tld) =>
  missingCo ? fullMatch : '.co.' + tld
)

const addMissingMForCom = R.pipe(R.replace(/\.co\.au$/, '.com.au'))

const addMissingOInComTld = R.replace(/\.cm$/, '.com')

const avoidSpamTraps = R.pipe(
  R.replace(/@gamil.*/, '@gmail.com'),
  R.replace(/@gmail\.comgmail\.com$/, '@gmail.com'),
  R.replace(/@yaho\./, '@yahoo.'),
  R.replace(/@yahoo7mail\./, '@y7mail.')
)

const removeDuplicatedAtSign = R.replace(/@+/, '@')

const removeDuplicatedPeriod = R.replace(/\.+/, '.')

const removeDuplicatedSuffixes = R.pipe(
  R.replace(/(\.(co|com))+$/, '$1'),
  R.replace(/\.co\.com$/, '.com'),
  R.replace(/\.com\.co\.uk$/, '.co.uk'),
  R.replace(/\.com\.(de|fr|sc)$/, '.$1'),
  R.replace(/\.(de|fr|sc)\.com$/, '.$1'),
  R.replace(/\.com\.net$/, '.com')
)

const removeSpaces = R.replace(/ /g, '')

const replaceMistypingGForGmail = R.replace(/@[rfvbnht]mail\./, '@gmail.')

export const addressCommonMisspellings = R.pipe(
  removeSpaces,
  removeDuplicatedAtSign,
  removeDuplicatedPeriod,
  addMissingCoCcSld,
  addMissingOInComTld,
  addMissingMForCom,
  addMissingBasenameSuffixSeparator,
  removeDuplicatedSuffixes,
  avoidSpamTraps,
  replaceMistypingGForGmail
)
