import * as S from 'apps/cliniko/style'
import * as _ from 'modules/util'

const FieldContainer = ({ as: Component = 'div', children, labelType, ...props }) => (
  <Component css={containerStyles({ inline: labelType === 'inline' })} {...props}>
    {children}
  </Component>
)

const containerStyles = ({ inline }) => ({
  position: 'relative',

  [S.legacy.media.small]: {
    display: _.when(inline, 'flex'),
  },
})

export default FieldContainer
