import * as S from 'apps/cliniko/style'
import Prose from 'components/prose.legacy'

const Label = ({ as = 'label', ...props }) => <Prose as={as} css={styles} {...props} />

const styles = {
  display: 'block',
  fontWeight: 'bold',
  color: S.legacy.colors.primary,
  marginRight: 0,
}

export default Label
