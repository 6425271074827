import { getUsernameAndDomain } from './email-address'
import suggest from './suggest'

const emailChecker = email => {
  const [username, domain] = getUsernameAndDomain(email)

  if (!domain || !username) {
    return
  }

  const [suggestedDomain, score] = suggest(domain)

  const suggestedEmail = `${username}@${suggestedDomain}`

  if (suggestedDomain && suggestedEmail !== email.toLowerCase() && meetsThreshold(score)) {
    return suggestedEmail
  }
}

const meetsThreshold = score => score > 0.79

export default emailChecker
