import * as Time from 'modules/time'

const Subscription = {}

Subscription.isTrialing = subscription => subscription?.status === 'trialing'

Subscription.daysRemainingInTrial = subscription =>
  Time.daysFromNowToString(subscription?.trialEndsAt)

export default Subscription
