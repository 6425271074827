import * as Resource from 'modules/resource'
import Application from './boot/application'
import PrivacyPolicy from './boot/privacy-policy'
import Subscription from './boot/subscription'

const Boot = Resource.make('boot')
Boot.Application = Application
Boot.PrivacyPolicy = PrivacyPolicy
Boot.Subscription = Subscription

export default Boot
