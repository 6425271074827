import * as S from 'apps/cliniko/style'
import Prose from 'components/prose.legacy'
import Transition from 'components/transition'

const ValidationMessage = ({ children, className, when }) => (
  <Transition
    role="alert"
    className={className}
    css={styles}
    enter={{ opacity: 1, transform: 'translateY(0)' }}
    from={{ opacity: 0, transform: 'translateY(-30%)' }}
    when={!!when}>
    <Prose>{children}</Prose>
  </Transition>
)

const styles = [
  S.legacy.space({ pt: 0.6, pb: 0.4, px: 1, mt: 0.5 }),
  {
    position: 'relative',
    borderRadius: S.borderRadius,
    backgroundColor: S.legacy.colors.warning,
    ':after': S.arrow({ position: 'top', color: S.legacy.colors.warning, left: S.rem(26) }),
  },
]

export default ValidationMessage
