import * as Resource from 'modules/resource'
import * as _ from 'modules/util'

const UserAgent = Resource.make('userAgent', { endpoint: '/user_agent' })

UserAgent.browser = {
  isChrome: userAgent => userAgent?.browser?.identifier === 'chrome',
  isEdge: userAgent => userAgent?.browser?.identifier === 'edge',
  isFirefox: userAgent => userAgent?.browser?.identifier === 'firefox',
  isSafari: userAgent => userAgent?.browser?.identifier === 'safari',

  isModern: userAgent => userAgent?.browser?.status === 'modern',
  isNotSupported: userAgent => userAgent?.browser?.status === 'unsupported',
  isObsolete: userAgent => userAgent?.browser?.status === 'obsolete',
  isOutdated: userAgent => userAgent?.browser?.status === 'outdated',
  isNonChromiumEdge: userAgent =>
    UserAgent.browser.isEdge(userAgent) && userAgent?.browser?.version < 79,
  isSafari11OrBelow: userAgent =>
    UserAgent.browser.isSafari(userAgent) && userAgent?.browser?.version < 12,
  supportsVideoCalls: userAgent =>
    !UserAgent.browser.isNonChromiumEdge(userAgent) &&
    !UserAgent.browser.isSafari11OrBelow(userAgent) &&
    _.isFunction(navigator?.mediaDevices?.getUserMedia) &&
    _.isFunction(window.RTCPeerConnection),
}

UserAgent.platform = {
  isIOS: userAgent => userAgent?.platform?.identifier === 'ios',
  isMacOS: userAgent => userAgent?.platform?.identifier === 'osx',
  isAndroid: userAgent => userAgent?.platform?.identifier === 'android',
  isIPhone: userAgent => userAgent?.platform?.identifier === 'iPhone',
  isIPad: userAgent => userAgent?.platform?.identifier === 'iPad',
}

export default UserAgent
