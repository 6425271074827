import * as Time from 'modules/time'
import * as _ from 'modules/util'

const PrivacyPolicy = {}

PrivacyPolicy.isAccepted = privacyPolicy => acceptedVersion =>
  Number(acceptedVersion) >= Number(privacyPolicy.currentVersion)

PrivacyPolicy.showBanner =
  ({ currentlyAcceptedPrivacyPolicy, registrationCompleted }) =>
  privacyPolicy =>
    registrationCompleted &&
    !_.thru(currentlyAcceptedPrivacyPolicy, PrivacyPolicy.isAccepted(privacyPolicy)) &&
    !PrivacyPolicy.enforcementDateIsInThePast(privacyPolicy)

PrivacyPolicy.enforcementDateIsInThePast = privacyPolicy =>
  Time.isInThePast(privacyPolicy.enforcementDate)

export default PrivacyPolicy
