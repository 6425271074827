import * as S from 'apps/cliniko/style'
import Heading from 'components/heading'
import Prose from 'components/prose'
import * as _ from 'modules/util'

const Label = ({ as = 'label', type, required, ...props }) =>
  type?.includes('heading') ? (
    <Heading
      as={as}
      css={headingStyles({ type })}
      styleLevel={_.cond(
        [type === 'section-heading', 2],
        [type === 'heading', 3],
        [type === 'sub-heading', 4]
      )}
      {...props}
    />
  ) : (
    <Prose as={as} css={regularStyles({ type, required })} {...props} />
  )

const headingStyles = ({ type }) => [{ display: 'block' }, type !== 'sub-heading' && S.space.mb(2)]

const regularStyles = ({ type, required }) => {
  if (type === 'hidden') return S.visuallyHidden
  return [
    {
      display: 'block',
      color: S.colors.grey(),
      fontWeight: 500,
    },
    required && {
      '&:after': {
        content: "'*'",
        color: S.colors.blue(),
        marginLeft: S.unit(0.5),
      },
    },
  ]
}

export default Label
