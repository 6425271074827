import { notify as notifyHoneybadger } from 'apps/error-reporting'
import Cookies from 'cookies-js'

const COOKIE_KEY = 'au1-migration-id'
const STORAGE_PARAM = 'store'
const ID_PARAM = 'id'

const subdomain = () => window.location.host.split('.')[0].toLowerCase()

const loadLocalstorage = () => {
  try {
    const url = new URL(window.location.href)
    if (!url.hash) {
      return
    }

    const params = new URLSearchParams(url.hash.replace(/^#/, ''))
    const paramValue = params.get(STORAGE_PARAM)
    if (!paramValue) {
      return
    }

    const storageId = params.get(ID_PARAM)
    const cookieValue = Cookies.get(COOKIE_KEY)

    if (cookieValue !== storageId) {
      console.error('Key could not be authenticated')

      params.delete(STORAGE_PARAM)
      params.delete(ID_PARAM)
      if (params.size > 0) {
        url.hash = `#${params.toString()}`
      } else {
        url.hash = ''
      }

      window.history.replaceState(null, document.title, url.toString())
      return
    }

    const storeData = JSON.parse(window.atob(params.get(STORAGE_PARAM)))

    Object.entries(storeData).forEach(([key, value]) => {
      window.localStorage.setItem(key, value)
    })

    Cookies.expire(COOKIE_KEY)
    Cookies.set(`au1-migrated-${subdomain()}`, '1', { expires: Infinity, domain: 'cliniko.com' })

    url.hash = ''
    window.history.replaceState(null, document.title, url.toString())
  } catch (e) {
    notifyHoneybadger(e)
  }
}

const migrateLocalstorage = () => {
  try {
    const url = new URL(window.location.href)
    url.host = url.host.replace(/(^|\.)cliniko\.com$/, '$1au1.cliniko.com')

    if (!Cookies.get(`au1-migrated-${subdomain()}`)) {
      const hash = new URLSearchParams()
      hash.set(STORAGE_PARAM, window.btoa(JSON.stringify(window.localStorage || {})))

      const storageId = crypto.randomUUID()
      Cookies.set(COOKIE_KEY, storageId, { domain: 'cliniko.com', expires: 60, secure: true })
      hash.set(ID_PARAM, storageId)

      url.hash = `#${hash.toString()}`
    }
    window.location.href = url.toString()
  } catch (e) {
    notifyHoneybadger(e)
  }
}

export const migrate = () => {
  if (
    !(window.environment === 'production' && window.CLINIKO_SHARD === 'au1' && window.au1Migration)
  ) {
    return
  }
  if (/^[^.]+\.au1\.cliniko\.com$/.test(window.location.host)) {
    loadLocalstorage()
  } else if (/^[^.]+\.cliniko\.com$/.test(window.location.host)) {
    migrateLocalstorage()
  }
}
