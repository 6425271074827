import * as _ from 'modules/util'
import * as R from 'ramda'
import { makeMaxLengthValidation, makeRequiredValidation } from '../validators'

const removeFalsyValues = R.filter(Boolean)

const requiredValidationRule = ({ label, required }) => {
  const options = _.isString(required) ? { message: required } : required
  return makeRequiredValidation({ label, ...options })
}

const validationRules = ({ label, max, maxLength, min, pattern, required, validate: validation }) =>
  removeFalsyValues({
    max: _.isPresent(max) && { value: max, message: `${label} cannot exceed ${max}` },
    min: _.isPresent(min) && { value: min, message: `${label} cannot be less than ${min}` },
    pattern,
    validate: removeFalsyValues({
      maxLength: !!maxLength && makeMaxLengthValidation({ label, maxLength }),
      required: !!required && requiredValidationRule({ label, required }),
      ...(_.isPresent(validation) && _.isObject(validation) ? validation : { validation }),
    }),
  })

export default validationRules
