import * as S from 'apps/cliniko/style'
import StyledTextInput from 'components/text-input'
import FieldContainer from './field-container'
import LegacyFieldContainer from './field-container.legacy'
import LegacyLabel from './label.legacy'
import { setValueAsString, useUncontrolledInput } from './util'
import LegacyValidationMessage from './validation-message.legacy'

const TextInput = ({ className, description, help, hint, legacyStyle, ...props }) => {
  const { label, ...inputProps } = useUncontrolledInput({
    maxLength: 255,
    setValueAs: setValueAsString,
    ...props,
  })

  const containerProps = {
    className,
    description,
    error: inputProps.error,
    help,
    hint,
    label,
    required: props.required,
  }

  if (legacyStyle) {
    return (
      <LegacyFieldContainer className={className} labelType={label.type}>
        <LegacyLabel {...label} />
        <div css={{ width: '100%' }}>
          <StyledTextInput legacyStyle={legacyStyle} {...inputProps} />
          <LegacyValidationMessage css={S.space.mt(1)} error={inputProps.error} />
        </div>
      </LegacyFieldContainer>
    )
  }

  return (
    <FieldContainer {...containerProps}>
      <StyledTextInput {...inputProps} />
    </FieldContainer>
  )
}

export default TextInput
