import * as S from 'apps/cliniko/style'
import StyledLabel from 'components/label.legacy'
import * as _ from 'modules/util'
import * as R from 'ramda'

const Label = ({ as: Component = StyledLabel, children, type, ...props }) => {
  const hidden = R.equals(type, 'hidden')
  const inline = R.equals(type, 'inline')

  return (
    <Component css={styles({ hidden, inline })} {...props}>
      {children}
    </Component>
  )
}

const styles = ({ hidden, inline }) => [
  _.when(
    inline,
    S.legacy.breakpoint.small({
      width: S.sizes.labelColumn,
      flex: '1 0 auto',
      paddingTop: S.rem(8),
      paddingBottom: S.rem(4),
    })
  ),
  _.when(hidden, S.visuallyHidden),
]

export default Label
