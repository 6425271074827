import { ArrayItemContext } from 'modules/form/array/context'
import * as _ from 'modules/util'
import * as R from 'ramda'
import { useContext, useId } from 'react'
import { useFormContext, useFormState } from 'react-hook-form'
import { toLabel } from './to-label'
import validationRules from './validation-rules'

export const useUncontrolledInput = ({
  clearable,
  disabled: disabledIn,
  id: idIn,
  label: labelIn,
  max,
  maxLength,
  min,
  name: nameIn,
  onBlur,
  onChange,
  required,
  setValueAs,
  validate,
  validateFields,
  validateOn,
  ...props
}) => {
  const randomId = useId()
  const id = idIn || randomId

  const { arrayName, index } = useContext(ArrayItemContext) ?? {}
  const name = [arrayName, index, nameIn].filter(_.isPresent).join('.')
  const getAtPath = R.path(name.split('.'))

  const {
    formState: { disabled: formDisabled, isSubmitting },
    labelType: formLabelType,
    register,
    setValue,
    trigger,
    unregister,
  } = useFormContext()

  _.useEffectOnce(() => () => unregister(name))

  const { errors, touchedFields } = useFormState({ name })
  const error = getAtPath(errors)
  const isTouched = getAtPath(touchedFields)

  const checkValidationOnChange = () => {
    if (!validateOn) return
    if (validateOn === 'submit') return
    if (validateOn === 'blur' && !isTouched) return
    trigger(name)
  }

  const checkValidationOnBlur = () => {
    if (validateOn !== 'blur') return
    trigger(name)
  }

  const labelText = labelIn?.text ?? labelIn ?? toLabel(name)

  const onChangeActions = _.actions(onChange, checkValidationOnChange)

  const inputProps = {
    'aria-invalid': Boolean(error),
    disabled: formDisabled || disabledIn || isSubmitting,
    error,
    id,
    label: {
      as: labelIn?.as,
      children: labelText,
      htmlFor: id,
      type: labelIn?.type || formLabelType,
    },
    ...props,
    ...register(name, {
      deps: validateFields,
      onBlur: _.actions(onBlur, checkValidationOnBlur),
      onChange: onChangeActions,
      setValueAs,
      ...validationRules({ label: labelText, max, maxLength, min, required, validate }),
    }),
  }

  if (clearable) {
    inputProps.onClear = () => {
      setValue(name, '')

      inputProps.onChange({
        target: {
          value: '',
          name,
        },
        type: 'change',
      })
    }
  }

  return inputProps
}
