import * as _ from 'modules/util'
import { useEffect, useState } from 'react'

export const useLocalStorage = (key, { defaultTo } = {}) => {
  const [value, setValue] = useState(() => {
    const persistedValue = window.localStorage.getItem(key)

    return persistedValue !== null
      ? JSON.parse(persistedValue)
      : _.isFunction(defaultTo)
        ? defaultTo()
        : defaultTo
  })

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value))
  }, [key, value])

  return [value, setValue]
}
