import * as R from 'ramda'
import { validEmailDomains } from './email-domains'
import { getBestSuggestion } from './get-best-suggestion'
import * as damerauLevenshteinSuggesters from './suggest-d-levenshtein'

const domainIsRecognized = domain => validEmailDomains.some(R.equals(domain))

const domainLacksSuffix = R.complement(R.includes('.'))

const suggestUsingSearchStrategies = R.pipe(
  R.applySpec(damerauLevenshteinSuggesters),
  Object.values,
  getBestSuggestion
)

const suggest = R.cond([
  [domainIsRecognized, suggestion => [suggestion, 1]],
  [domainLacksSuffix, () => [undefined, 0]],
  [R.T, suggestUsingSearchStrategies],
])

export default suggest
