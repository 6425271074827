import * as R from 'ramda'

export const getBasenameAndSuffix = R.memoizeWith(
  R.identity,
  R.pipe(R.split('.'), labels => {
    if (labels.length < 2) {
      return []
    }

    const tld = labels.pop()
    const ccSld = labels.length > 1 && looksLikeCcSld(R.last(labels)) && labels.pop()
    return [labels.join('.'), [ccSld, tld].filter(Boolean).join('.')]
  })
)

export const getBasename = R.pipe(getBasenameAndSuffix, R.prop(0))

export const getSuffix = R.pipe(getBasenameAndSuffix, R.prop(1))

const looksLikeCcSld = label =>
  label.length < 3 || ['com', 'net', 'org', 'edu', 'gov', 'int', 'mil'].includes(label)
