import damerauLevenshtein from 'damerau-levenshtein'
import * as R from 'ramda'
import { thru } from '../util/functions'
import { weightedGeometricMean } from '../util/numbers'
import { getBasename, getSuffix } from './domain'
import {
  basenameWeight,
  suffixWeight,
  validBasenames,
  validEmailDomains,
  validSuffixesForBasename,
} from './email-domains'
import { getBestSuggestion } from './get-best-suggestion'

const levenshteinDistances = R.curry((suggestions, input) =>
  suggestions.map(suggestion => [suggestion, damerauLevenshtein(suggestion, input).similarity])
)

const weightSimilarity = R.curry((weight, similarity) => 1 - (1 - similarity) ** weight)

const weightLevenshteinDistance = R.curry((getWeightFn, [suggestion, similarity]) => [
  suggestion,
  weightSimilarity(getWeightFn(suggestion), similarity),
])

export const suggestEntireDomain = domain => {
  const getWeight = suggestion =>
    R.multiply(
      basenameWeight(getBasename(suggestion)),
      suffixWeight(getBasename(suggestion), getSuffix(suggestion))
    )
  return thru(
    domain,
    levenshteinDistances(validEmailDomains),
    R.map(weightLevenshteinDistance(getWeight)),
    getBestSuggestion
  )
}

const suggestBasename = R.pipe(
  getBasename,
  levenshteinDistances(validBasenames),
  R.map(([suggestion, similarity]) => [
    suggestion,
    weightSimilarity(basenameWeight(suggestion), similarity),
  ]),
  getBestSuggestion,
  R.defaultTo([undefined, 0])
)

const suggestSuffix = domain => {
  const basename = thru(domain, suggestBasename, R.head)
  return thru(
    levenshteinDistances(validSuffixesForBasename(basename), getSuffix(domain)),
    R.map(weightLevenshteinDistance(suffixWeight(basename))),
    getBestSuggestion,
    R.defaultTo([undefined, 0])
  )
}

const combineBasenameSuffixTuples = (
  [basename, basenameSimilarity],
  [suffix, suffixSimilarity]
) => {
  const suggestion = `${basename}.${suffix}`
  const combinedSimilarity =
    suffixSimilarity === 0 && suffixWeight(basename, suffix) !== 0
      ? basenameSimilarity
      : weightedGeometricMean([10, 1], [basenameSimilarity, suffixSimilarity])

  return [suggestion, combinedSimilarity]
}

export const suggestBasenameSuffix = R.converge(combineBasenameSuffixTuples, [
  suggestBasename,
  suggestSuffix,
])
