import StyledLabel from 'components/label'
import LegacyStyledLabel from './label.legacy'

const Label = ({ legacyStyle, type, ...props }) =>
  legacyStyle ? (
    <LegacyStyledLabel type={type} {...props} />
  ) : (
    <StyledLabel type={type} {...props} />
  )

export default Label
