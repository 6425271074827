import * as S from 'apps/cliniko/style'
import * as React from 'react'

const PlainButton = React.forwardRef(({ type = 'button', ...props }, ref) => (
  <button css={styles} ref={ref} type={type} {...props} />
))

const styles = {
  background: 'transparent',
  fontSize: S.rem(16),
  color: S.colors.grey(),
  WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',

  '&[data-focus-visible-added]': {
    zIndex: 2,
    outline: `2px solid ${S.colors.focus}`,
    outlineOffset: 2,
    color: 'inherit',
  },

  '&:disabled': {
    cursor: 'default',
    pointerEvents: 'none',
  },
}

export default PlainButton
