import * as _ from 'modules/util'
import * as R from 'ramda'

const sanitizeValue = value => {
  if (value?._f?.value !== undefined) return value._f.value
  if (_.isObject(value)) return sanitizeControlFields(value)
  if (_.isArray(value)) return value.map(sanitizeValue)
  return value
}

export const sanitizeControlFields = R.pipe(R.omit(['_f']), R.map(sanitizeValue))
