import * as S from 'apps/cliniko/style'
import StyledCheckbox from 'components/checkbox'
import Prose from 'components/prose'
import FieldContainer from './field-container'
import { setValueAsBoolean, useUncontrolledInput } from './util'
import LegacyValidationMessage from './validation-message.legacy'

const Checkbox = ({ children, className, description, help, hint, legacyStyle, ...props }) => {
  const { label, ...inputProps } = useUncontrolledInput({
    setValueAs: props.value ? undefined : setValueAsBoolean,
    ...props,
  })

  const containerProps = {
    className,
    error: inputProps.error,
    help,
    required: props.required,
  }

  if (legacyStyle) {
    return (
      <div className={className} css={{ position: 'relative' }}>
        <StyledCheckbox legacyStyle={legacyStyle} {...inputProps}>
          {children}
        </StyledCheckbox>
        <LegacyValidationMessage css={S.space.mt(1)} error={inputProps.error} />
      </div>
    )
  }

  return (
    <FieldContainer {...containerProps}>
      <StyledCheckbox css={S.space.mb(1)} {...inputProps}>
        {children || label.children}
      </StyledCheckbox>
      {!!hint && (
        <Prose css={S.space({ mt: -2, mb: 1, ml: 5 })} size="small">
          {hint}
        </Prose>
      )}
      {!!description && (
        <div css={{ color: S.colors.grey(5), ...S.space({ mt: -1, ml: 5 }) }}>{description}</div>
      )}
    </FieldContainer>
  )
}

export default Checkbox
