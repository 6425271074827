import * as S from 'apps/cliniko/style'
import Prose from 'components/prose'
import Label from './label'
import ValidationMessage from './validation-message'

const FieldContainer = ({
  as: Wrapper = 'div',
  children,
  description,
  error,
  help,
  label,
  hint,
  required,
  ...props
}) => (
  <Wrapper css={!!help && S.grid('1fr auto', { gap: `0 ${S.unit(1)}` })} {...props}>
    {!!label && <Label {...label} required={required} />}
    {help}
    <div css={{ color: S.colors.grey(5), gridColumn: '1 / 3' }}>
      {description}
      {children}
      {!!hint && (
        <Prose light size="small">
          {hint}
        </Prose>
      )}
      {!!error && <ValidationMessage css={S.space.mt(1)} error={error} />}
    </div>
  </Wrapper>
)

export default FieldContainer
